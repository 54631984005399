import styled from 'styled-components'

const Link_ = styled.a`
    color: #6745b0;
    text-decoration-line: underline;
    margin-top: 10px;
    margin-bottom: 10px;
    display: block;
`

export default Link_;
