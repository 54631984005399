import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import Header1 from "../styles/mdx-styles/Header1"
import Header2 from "../styles/mdx-styles/Header2"
import Header3 from "../styles/mdx-styles/Header3"
import Paragraph from "../styles/mdx-styles/Paragraph"
import Link_ from "../styles/mdx-styles/Link"
import CodeBlock from "../styles/mdx-styles/CodeBlock"

import Layout from "../components/layout"
import Seo from "../components/seo"

const mdxComponents = {
  h1: Header1,
  h2: Header2,
  h3: Header3,
  p: Paragraph,
  Link: Link_,
  CodeBlock: CodeBlock,
}

const BlogPost = ({ data: { mdx } }) => (
  <Layout>
    <Seo title="BlogPost" />
    <main className="flex-shrink-0">
      <section className="py-5">
        <div className="container px-5 my-5">
          <div className="row gx-5">
            <div className="col-lg-2"></div>
            <div className="col-lg-10">
              <article>
                <header className="mb-4">
                  <h1 className="fw-bolder mb-1">{mdx.frontmatter.title}</h1>
                  <div className="text-muted fst-italic mb-2">
                    UPDATED: {mdx.frontmatter.date}
                  </div>
                </header>
                <figure className="mb-4">
                  <GatsbyImage
                    image={
                      mdx.frontmatter.image.childImageSharp.gatsbyImageData
                    }
                    alt=""
                  />
                </figure>
                <section className="mb-5">
                  <MDXProvider components={mdxComponents}>
                    <MDXRenderer>{mdx.body}</MDXRenderer>
                  </MDXProvider>
                </section>
              </article>
            </div>
          </div>
        </div>
      </section>
    </main>
  </Layout>
)

export default BlogPost

export const query = graphql`
  query BlogPostQuery($slug: String) {
    mdx(slug: { eq: $slug }) {
      frontmatter {
        category
        title
        date
        excerpt
        image {
          childImageSharp {
            gatsbyImageData(
              formats: AUTO
              quality: 90
              placeholder: BLURRED
              width: 900
            )
          }
        }
      }
      slug
      body
    }
  }
`
