import styled from 'styled-components'

const CodeBlock = styled.pre`
    background-color: #000;
    color: #fafafa;
    padding: 20px;
    border-radius: 5px;
    display: inline-block;
    overflow-x: auto;
    width: 100%;
    margin-right: 0px;
    margin-left: 0px;
`

export default CodeBlock;
